<template>
    <v-row>
        <v-dialog v-model="dialogErro.status" persistent>
            <CardDialog :dialog="dialogErro" />
        </v-dialog>
        <v-dialog v-model="dialog" hide-overlay persistent>
            <v-card color="primary" dark>
                <v-card-text class="pa-5">
                    Aguarde seu arquivo está sendo enviado.
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="12">
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-row class="mb-n10">
                            <v-col class="d-flex pl-1 pr-2">
                                <v-file-input
                                    label="Selecione o arquivo de texto"
                                    outlined
                                    dense
                                    :loading="carregando"
                                    accept=".txt"
                                    @change="adicionarArquivo"
                                ></v-file-input>
                                <v-btn
                                    :loading="dialog"
                                    class="ml-2"
                                    height="39"
                                    large
                                    color="primary"
                                    elevation="0"
                                    @click="enviarArquivo()"
                                >
                                    <v-icon class="mr-sm-3">mdi-file-plus-outline</v-icon>
                                    <span class="d-none d-sm-block">Importar arquivo</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-card-text class="text-center pt-8">
                    <strong
                        class="text-h6"
                    >Selecione o arquivo de texto com extenção .txt desejado e depois clique no botão "enviar arquivo" para efetuar a importação dos salários.</strong>
                    <br />
                    <br />
                    <v-icon
                        color="primary lighten-5"
                        class="mt-5"
                        size="150"
                    >mdi-folder-upload-outline</v-icon>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";

export default {
    name: "DepartamentoPessoalImportadorSalarios",
    components: { CardDialog },
    data: () => ({
        dialog: false,
        dialogErro: {
            status: false,
            title: "",
            msg: "",
            icon: "",
            iconColor: "",
        },
        busca: {
            dtmes: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }`,
            idfiliais: [],
        },
        arquivos: [],
        carregando: false,
    }),
    computed: {
        ...mapState(["backendUrl"]),
    },
    methods: {
        adicionarArquivo(arquivos) {
            let obj = {};
            obj.tipo =
                arquivos.name.split(".")[arquivos.name.split(".").length - 1];
            let reader = new FileReader();
            reader.readAsDataURL(arquivos);
            reader.onload = function () {
                obj.arquivo = reader.result.split("base64,")[1];
            };
            this.arquivos.push(obj);
        },
        enviarArquivo() {
            this.carregando = true;
            this.dialog = true;
            return axios
                .post(`${this.backendUrl}dp/importar-arquivo`, {
                    tipo: this.arquivos[0].tipo,
                    arquivo: this.arquivos[0].arquivo,
                })
                .then((res) => {
                    if (res.data) {
                        console.log(res.data);
                    } else {
                        this.dialogErro.title = "Erro";
                        if (res.data.erro) {
                            this.dialogErro.msg =
                                "Não foi possível realizar o envio do arquivo!";
                        } else {
                            this.dialogErro.msg = res.data.erro;
                        }
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    }
                    this.dialog = false;
                    this.carregando = false;
                })
                .catch((e) => {
                    this.dialogErro.title = "Erro";
                    if (e.response.data.erro) {
                        this.dialogErro.msg = e.response.data.erro;
                    } else {
                        this.dialogErro.msg =
                            "Não foi possível realizar o envio do arquivo!";
                    }
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                    this.dialog = false;
                    this.carregando = false;
                });
        },
    },
    created() {},
};
</script>